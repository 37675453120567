import { BLACK_HEX, GREEEN_HEX, ORANGE_HEX, WHITE_HEX } from "../config/constants"

export const data = {
    lab: {
        title: {
            ARM:"լաբորատոր սարքավորումներ և նյութեր",
            ENG:"laboratory equipment and materials",
            RUS:"лабораторное оборудование и материалы"
        },
        desc: [
            {
                type: 'text',
                ARM: 'Արտադրական, լաբորատոր սարքավորումների և քիմիական ծախսանյութերի, հավաքածուների և ռեակտիվների  մատակարարում, մոնտաժ և շահագործման հանձնում, որոնք նպատակաուղղված են  ինչպես արտադրությունների, այնպես էլ արտադրական, գիտական, բժշկական և անասնաբուժական նշանակության լաբորատորիաների վերազինմանը և ժամանակակից չափանիշներին համապատասխան փորձաքննությունների իրականացմանը: ',
                ENG: 'Delivery, installation, start-up, adjustment, service of production and laboratory equipment. Equipping and re-profiling both production and scientific, medical and veterinary laboratories, as well as conducting and implementing research and analysis methods in accordance with the modern high standards. Supply of chemicals and consumables, standard kits, reagents, etc., in accordance with the current quality standards.',
                RUS: 'Поставка, монтаж, пуск, наладка, сервис производственного и лабораторного оборудования. Оснащение и перепрофилирование как производственных, так и научных, медицинских и ветеринарных лабораторий, а также проведение и внедрение методов исследований и анализа в соответствии с современными стандартами. Поставка химических и расходных материалов, стандартных наборов, реагентов и т.д. в соответствии с современными стандартами качества'
            },
            {
                type: 'text',
                ARM: 'ԼաբՔեր Ընդ Քընսալթինգ ընկերության առաջարկային փաթեթում առկա են մի շարք հանրաճանաչ կազմակերպությունների արտադրատեսակներ՝',
                ENG: "In the Company's portfolio, “LabCare & Consulting” includes products from a number of well-known companies:",
                RUS: 'В портфолио компании . LabCare & Consulting входят продукты ряда известных компаний:'
            },
            {
                type: 'listItem',
                ARM: 'բժշկական, գիտական և անասնաբուժական նշանակության արտադրանք',
                ENG: 'Medical, scientific, veterinary goods.',
                RUS: 'Медицинские, научные , ветеринарные товары.'
            },
            {
                type: 'listItem',
                ARM: 'արտադրական և լաբորատոր նշանակության սարքավորումներ և միանգամյա օգտագործման պարագաներ',
                ENG: 'Manufacturing and laboratory equipment, as well as disposable items.',
                RUS: 'Производственное и лабораторное оборудование, также предметы одноразового употребления.'
            },
            {
                type: 'listItem',
                ARM: 'բժշկական և կենսաբանական ուղղվածության սարքեր/ սարքավորումներ, կենսաքիմիական, գենետիկական տարբեր հետազոտությունների համար նախատեսված հավաքածուներ',
                ENG: 'Medical and biological equipment, kits for various biochemical and genetic tests.',
                RUS: 'Медико-биологические оборудование, наборы для различных биохимических и генетических тестов.'
            },
            {
                type: 'listItem',
                ARM: 'քիմիական ռեակտիվներ և ապակեղեն:',
                ENG: 'Chemical reagents, laboratory equipment.',
                RUS: 'Химические реактивы, лабораторный инвентарь.'
            },
            {
                type: 'link',
                url: '/products',
                text: {
                    ARM: 'այստեղ',
                    ENG: 'here',
                    RUS: 'здесь',
                }
            },
        ],
        minDesc: {
            ARM: 'Արտադրական, լաբորատոր սարքավորումների և քիմիական ծախսանյութերի, հավաքածուների և ռեակտիվների  մատակարարում, մոնտաժ և շահագործման հանձնում, որոնք նպատակաուղղված են  ինչպես արտադրությունների, այնպես էլ արտադրական, գիտական, բժշկական և անասնաբուժական նշանակության լաբորատորիաների վերազինմանը և ժամանակակից չափանիշներին համապատասխան ...',
            ENG: 'Delivery, installation, start-up, adjustment, service of production and laboratory equipment. Equipping and re-profiling both production and scientific, medical and veterinary laboratories, as well as conducting and implementing research and analysis methods in accordance with the modern high standards. Supply of chemicals and consumables, standard kits,  ...',
            RUS: 'Поставка, монтаж, пуск, наладка, сервис производственного и лабораторного оборудования. Оснащение и перепрофилирование как производственных, так и научных, медицинских и ветеринарных лабораторий, а также проведение и внедрение методов исследований и анализа в соответствии с современными стандартами. Поставка химических и расходных материалов, стандартных  ...'
        },
        icon: "https://www.dropbox.com/s/ktbv0qhuv2k1bgz/microscope%20%281%29.png?raw=1",
        backgroundColor: GREEEN_HEX,
        color: WHITE_HEX
    },
    mining: {
        title: {
            ARM:"Հանքարդյունաբերություն և հետախուզում",
            RUS:"разведка и добыча руды",
            ENG:"exploration and mining of ore"
        },
        desc: [
            {
                type: 'text',
                ARM: 'Հանքարդյունաբերության համար անհրաժեշտ սարքերի, էտալոնային  ստանդարտների, ռեակտիվների և պարագաների  մատակարարում,  մոնտաժ, շահագործման հանձնում, անձնակազմի ուսուցում: ',
                ENG: 'Supply of equipment, standards, reagents, components for the mining industry, installation, commissioning of equipment and training of personnel.',
                RUS: 'Поставка оборудования, эталонов, реагентов, комплектующих для горнодобывающей промышленности, монтаж, пусконаладка оборудования и обучение персонала.'
            },
            {
                type: 'text',
                ARM: 'Մեր թիմը պատրաստ է աջակցել հանքարդյունաբերական ոլորտում  նշանակալի ուշադրություն պահանջող էկոլոգիական մոնիտորինգի ցանկացած խնդրի լուծմանը՝ առաջարկելով  և ներդնելով միջազգային առաջատար ընկերությունների կողմից ներկայացվող տեխնիակական և միջազգային և տեղական բոլոր ստանդարտներին բավարարող լուծումներ : ',
                ENG: 'Our team is ready to support you in solving any environmental monitoring problem that requires significant attention in the mining sector, offering solutions that meet all technical, international and local standards provided by leading international companies.',
                RUS: 'Наша команда готова поддержать вас в решении любой проблемы экологического мониторинга, требующего значительного внимания в горнодобывающем секторе, предлагая решения, соответствующие всем техническим, международным и местным стандартам, предоставляемым ведущими международными компаниями.'
            },
            {
                type: 'text',
                ARM: 'ԼաբՔեր Ընդ Քընսալթինգ ընկերությունը  համագործակցում է  հանքարդյունաբերական ոլորտում  հայտնի մի շարք ընկերությունների հետ՝  ապահովելով մեր հաճախորդներին որակյալ և միջազգային բարձր չափանիշներին  համարժեք  ծառայությունների մատուցում: ',
                ENG: '“LabCare & Consulting” cooperates with a number of well-known companies in the mining industry, providing our clients with quality services that meet high international standards.',
                RUS: 'LabCare & Consulting сотрудничает с рядом известных компаний в горнодобывающей отрасли, предоставляя нашим клиентам качественные услуги, соответствующие высоким международным стандартам.'
            },
            {
                type: 'link',
                url: '/products',
                text: {
                    ARM: 'այստեղ',
                    ENG: 'here',
                    RUS: 'здесь',
                }
            },
        ],
        minDesc: {
            ARM: 'Մեր թիմը պատրաստ է աջակցել հանքարդյունաբերական ոլորտում  նշանակալի ուշադրություն պահանջող էկոլոգիական մոնիտորինգի ցանկացած խնդրի լուծմանը՝ առաջարկելով  և ներդնելով միջազգային առաջատար ընկերությունների կողմից ներկայացվող տեխնիակական և միջազգային և տեղական բոլոր ստանդարտներին բավարարող լուծումներ ...',
            ENG: 'Supply of equipment, standards, reagents, components for the mining industry, installation, commissioning of equipment and training of personnel. Our team is ready to support you in solving any environmental monitoring problem that requires significant attention in the mining sector ...',
            RUS: 'Поставка оборудования, эталонов, реагентов, комплектующих для горнодобывающей промышленности, монтаж, пусконаладка оборудования и обучение персонала. Наша команда готова поддержать вас в решении любой проблемы экологического мониторинга , требующего значительного внимания в горнодобывающем секторе...'
        },
        icon: "https://www.dropbox.com/s/7zrioeqq3o6rq9n/labor-man.png?raw=1",
        backgroundColor: BLACK_HEX,
        color: WHITE_HEX
    },
    yeast: {
        title: {
            ARM:"խմորիչ և խմորիչ ածանցյալներ",
            RUS:"дрожжи и производные дрожжей",
            ENG:"yeast and yeast derivatives"
        },
        desc: [
            {
                type: 'text',
                ARM: 'Խմորման, արտադրությունների համար նախատեսված ակտիվ չոր խմորասնկերի, նրանց ածանցյալներից պատրաստված հավելումների մատակարարում, խորհրդատվության տամադրում: ',
                ENG: 'Supply of active dry yeast and additives from their derivatives for the production of alcoholic and non-alcoholic beverages, providing advice.',
                RUS: 'Поставка активных сухих дрожжей и добавок из их производных для производства алкогольных и безалкогольных напитков, предоставление консультации.'
            },
            {
                type: 'text',
                ARM: 'ԼաբՔեր Ընդ Քընսալթինգ ընկերությունը, հանդիսանալով ֆրանսիական Lesaffre Grupe ընկերության Fermentis ապրանքանիշի պաշտոնական ներկայացուցիչը Հայաստանի Հանրապետությունում, առաջարկում է ցանկացած տեսակի ալկոհոլային, ինչպես նաև ոչ ալկոհոլային խմիչքների արտադրության համար նախատեսված ակտիվ չոր խմորասնկերի, բնական խմորասնկերից պատրաստված խմորման բարելավիչների և այլ օժանդակ նյութերի լայն տեսականի: ',
                ENG: '“LabCare & Consulting” is the official distributor of Fermentis (trademark of the French company Lesaffre Grupe) in the Republic of Armenia.',
                RUS: 'LabCare & Consulting, является официальным дистрибьютором Fermentis( торговой марки французской компании Lesaffre Grupe) в Республике Армения.'
            },
            {
                type: 'text',
                ARM: 'Fermentis ապրանքանիշի չոր ակտիվ խմորասնկերի և մեր պրոֆեսիոնալ մասնագետների խորհրդատվության շնորհիվ   հնարավոր է արտադրել ցակացած տեսակի բարձորակ խմիչք (օղի, գինի, փրփրուն գինիներ, գարեջուր, սիդր, կվաս, մրգային օղիներ, կոնյակի գինենյութ և այլն):',
                ENG: 'Fermentis offers a wide range of active dry yeasts, as well as fermentation improvers from natural yeast and other auxiliary materials for the production of all types of alcoholic and non-alcoholic beverages.',
                RUS: 'Fermentis предлагает широкий ассортимент активных сухих дрожжей, а также улучшители брожения из натуральных дрожжей и других вспомогательных материалов для производства всех видов алкогольных и безалкогольных напитков. Используя сухие активные дрожжи марки Fermentis, по консультации и советам наших профессионалов можно производить любые алкогольные напитки высокого качества (водка, вино, игристые вина, пиво, сидр, квас, фруктовая водка, бренди и т. д.)'
            },
            {
                type: 'text',
                ARM: 'Մենք առաջարկում ենք լավագույնը՝ միջազգային ստանդարտներին և փորձին համապատասխան որակյալ խմիչք ստանալու համար, որն իր համային յուրահատուկ հատկանիշներով կառանձնացնի Ձեր արտադրանքը՝ շոյելով ամենախստապահանջ գուրմաների նրբագույն քիմքը:',
                ENG: 'Using dry active yeast of the Fermentis brand, by the advice of our professionals, you can produce any high quality alcoholic beverages (vodka, wine, sparkling wines, beer, cider, kvass, fruit vodka, brandy, etc.).',
                RUS: 'Мы предлагаем лучшее, для производства качественных напитков в соответствии с международными стандартами и опытом, и это выделит ваш продукт своим неповторимым вкусом, ласкающим изысканный вкус самых требовательных гурманов.'
            },
            {
                type: 'link',
                url: '/products',
                text: {
                    ARM: 'այստեղ',
                    ENG: 'here',
                    RUS: 'здесь',
                }
            },
        ],
        minDesc: {
            ARM: 'Խմորման, արտադրությունների համար նախատեսված ակտիվ չոր խմորասնկերի, նրանց ածանցյալներից պատրաստված հավելումների մատակարարում, խորհրդատվության տամադրում: ԼաբՔեր Ընդ Քընսալթինգ ընկերությունը, հանդիսանալով ֆրանսիական Lesaffre Grupe ընկերության Fermentis ապրանքանիշի պաշտոնական ներկայացուցիչը Հայաստանի Հանրապետությունում, առաջարկում է ցանկացած տեսակի ...',
            ENG: 'Supply of active dry yeast and additives from their derivatives for the production of alcoholic and non-alcoholic beverages, providing advice. “LabCare & Consulting” is the official distributor of Fermentis (trademark of the French company Lesaffre Grupe) in the Republic of Armenia. Fermentis offers a wide range of active dry yeasts, as well as fermentation ...',
            RUS: 'Поставка активных сухих дрожжей и добавок из их производных для производства алкогольных и безалкогольных напитков, предоставление консультации. LabCare & Consulting, является официальным дистрибьютором Fermentis( торговой марки французской компании Lesaffre Grupe) в Республике Армения. Fermentis предлагает широкий ассортимент активных сухих дрожжей ...'
        },
        icon: "https://www.dropbox.com/s/di2mkafshc779h1/wine.png?raw=1",
        backgroundColor: ORANGE_HEX,
        color: WHITE_HEX
    },
    trainings: {
        title: {
            ARM:"դասընթացներ և խորհրդատվություն",
            RUS:"курсы и консалтинг",
            ENG:"courses and consulting"
        },
        desc: [
            {
                type: 'text',
                ARM: 'Ծառայությունների մատուցում, դասընթացների կազմակերպում և խորհրդատվության տրամադրում :',
                ENG: 'Provision of services (including consulting services) and organization of courses.',
                RUS: 'Оказание услуг, организация курсов и предоставление консультационных услуг.'
            },
            {
                type: 'text',
                ARM: 'ԼաբՔեր Ընդ Քընսալթինգ ընկերությունը, համապատասխան մասնագիտական գիտելիքներ և ոլորտում երկարամյա աշխատանքային փորձ ունեցող իր պրոֆեսինոալ թիմով առաջինն է Հայաստանում, որ առաջարկում է լինել Ձեր գործընկերը, աշխատակիցն ու օգնականն ինչպես անալիտիկ լաբորատորիաների հիմնման, բարելավման և վերազինման փուլերում, այնպես էլ հետագա սպասարկման ողջ ընթացքում: Դա նշանակում է, որ ընտրելով մեզ որպես Ձեր գործընկեր, ընտրում եք Ձեր բոլոր հարցերի, խնդիրների լուծման, ցանկալի արդյունքին հասնելու ճանապարհը: ',
                ENG: 'The professional team of “LabCare & Consulting” with its professional knowledge and many years of experience is one of the firsts in the Republic of Armenia, if not the only one in this field, which can become your partner, employee and assistant both at the stages of creating, improving and equipping analytical laboratories, and throughout further service. This means that by becoming our partner, you choose the best way to solve all your problems with the achievement of the desired result.',
                RUS: 'Профессиональная команда компании LabCare & Consulting со своими профессиональными знаниями и многолетним опытом - одна из первых в Армении, если не единственная в этой области, которая может стать вашим партнером, сотрудником и помощником как на этапах создания, улучшения и оснащения аналитических лабораторий, так и на протяжении дальнейшего обслуживания. Это означает что, становясь нашим партнером, вы выбираете лучший способ решения всех своих задач с достижением желаемого результата.'
            },
            {
                type: 'text',
                ARM: 'ԼաբՔեր Ընդ Քընսալթինգ ընկերությունն առաջարկում է՝',
                ENG: '“LabCare & Consulting” offers:',
                RUS: 'LabCare & Consulting предлагает:'
            },
            {
                type: 'listItem',
                ARM: 'Լաբորատոր շենքերի և շինությունների նախագծում, շինարարական ընկերությունների ընտրություն և շինարարական, մոնտաժային բոլոր գործընթացների վերահսկում՝ միջազգային չափորոշիչների պահանջներին համապատասխան,',
                ENG: 'Design of laboratories and laboratory buildings, selection of construction companies, control of construction and installation processes in accordance with the requirements of the international standards.',
                RUS: 'Проектирование лабораторий и лабораторных корпусов, подбор строительных компаний, контроль строительно-монтажных процессов в соответствии с требованиями международных стандартов;'
            },
            {
                type: 'listItem',
                ARM: 'Լաբորատոր կահույքի նախագծում և պատրաստում, ինչպես նաև ներկրում միջազգային լավագույն արտադրողներից,',
                ENG: "Development, manufacturing of laboratory furniture, as well as import of laboratory furniture from the world's best manufacturers.",
                RUS: 'Разработка, изготовление лабораторной мебели, а также импорт лабораторной мебели от лучших мировых производителей;'
            },
            {
                type: 'listItem',
                ARM: 'Պատվիրատուի կողմից առաջադրված խնդրի էությունից ելնելով՝ միջազգային և տեղական չափորոշիչների համաձայն անալիտիկ մեթոդների ընտրություն, փոխանցում և հարմարեցում,',
                ENG: ' Selection, transfer and adaptation of analytical methods of analysis in accordance with the international and local standards, based on the nature of the problems proposed by the customer.',
                RUS: 'Подбор, трансфер и адаптация аналитических методов анализа в соответствии с международным и местным стандартами, исходя из сути проблем предложенным заказчиком;'
            },
            {
                type: 'listItem',
                ARM: 'Կիրարկվող անալիտիկ մեթոդներին համապատասխան անհրաժեշտ սարքերի, սարքավորումների, լաբորատոր պարագաների, ապակեղենի, ռեագենտների, ստանդարտ նմուշների, շտամների, մանրէաբանական սննդամիջավայրերի, նույնականացման էքսպրես հավաքածուների, ջրի մաքրման համակարգերի,  օդափոխման համակարգերի, քարշիչ պահարանների, անհատական պաշտպանիչ միջոցների, ինչպես նաև այլ անհրաժեշտ համակարգերի ընտրություն, ներկրում և տեղադրում՝ համաձայն պատվիրատուի կողմից ներկայացված պահանջների և միջազգային լավագույն փորձի,',
                ENG: 'Selection, import, installation, service of the necessary laboratory equipment, laboratory glassware, reagents, standard samples, strains of microorganisms, microbiological media, kits for express identification, water purification systems, ventilation systems, traction cabinets, other personal protective equipment, in accordance with analytical methods and customer requirements in accordance with the international best practice',
                RUS: 'Подбор, импорт, инсталляция, сервис необходимого лабораторного оборудования, лабораторной посуды, реактивов, стандартных образцов, штаммов микроорганизмов, микробиологических сред, наборов для экспресс-идентификации, систем очистки воды, систем вентиляции, тяговых шкафов, других средств индивидуальной защиты, в соответствии с аналитическими методами и требованиями заказчика в соответствии с лучшей международной практикой;'
            },
            {
                type: 'listItem',
                ARM: 'Լաբորատոր սարքերի և սարքավորումների հետվաճառքային սպասարկում, վերանորոգում և որակավորում (IQ, OQ, PQ ),',
                ENG: 'Service and repair of laboratory instruments and equipment, certification (IQ, OQ, PQ).',
                RUS: 'Сервисное обслуживание и ремонт лабораторных приборов и оборудования, аттестация (IQ, OQ, PQ),'
            },
            {
                type: 'listItem',
                ARM: 'Տեղում լաբորատոր անձնակազմի ուսուցում և վերապատրաստում,',
                ENG: 'Training and professional development of laboratory personnel in the workplace.',
                RUS: 'Обучение и повышение квалификации лабораторного персонала на рабочем месте;'
            },
            {
                type: 'listItem',
                ARM: 'Հաճախորդի կողմից առաջարկվող ցանկացած բարդության խնդիրների և առաջարկների ուսումնասիրություն, լուծումների ներկայացում և դրանց իրականացում՝ համաձայն միջազգային լավագույն փորձի: Յուրաքանչյուր առաջադրանք կատարելիս, խնդիր լուծելիս, համակարգ ներդնելիս  գործընթացում ընդգրկվում է ոչ միայն ԼաբՔեր Ընդ Քընսալթինգ  ընկերության Հայաստանում գործող պրոֆեսիոնալ թիմը, այլ նաև համագործակցության հիմքի վրա մեր եվրոպական, ամերիկյան և ավստրալիական գործընկեր- կազմակերպությունները, որոնք  հանդիսանում են ոլորտում լավագույններից մեկը: ',
                ENG: 'Studying tasks of any complexity and problems proposed by the customer, providing solutions and their implementation in accordance with the best world practices. Not only the professional team of “LabCare & Consulting” in the Republic of Armenia, but also our European, American and Australian partner organizations, which are among the bests in this field, are involved in the implementation of each task, solving problems, introducing the system into the process.',
                RUS: 'Изучение задач любой сложности и проблем, предложенных заказчиком, предоставление решений и их реализация в соответствии с лучшими мировыми практиками. В выполнение каждой задачи, решение проблем, внедрение системы в процесс вовлечены не только профессиональная команда LabCare & Consulting в Армении, но и наши европейские, американские и австралийские партнерские организации, которые являются одними из лучших в этой области.'
            },
            {
                type: 'link',
                url: '/products',
                text: {
                    ARM: 'այստեղ',
                    ENG: 'here',
                    RUS: 'здесь',
                }
            },
        ],
        minDesc: {
            ARM: 'Ծառայությունների մատուցում, դասընթացների կազմակերպում և խորհրդատվության տրամադրում: ԼաբՔեր Ընդ Քընսալթինգ ընկերությունը, համապատասխան մասնագիտական գիտելիքներ և ոլորտում երկարամյա աշխատանքային փորձ ունեցող իր պրոֆեսինոալ թիմով առաջինն է Հայաստանում, որ առաջարկում է լինել Ձեր գործընկերը, աշխատակիցն ու օգնականն ինչպես անալիտիկ լաբորատորիաների հիմնման ...',
            ENG: 'Provision of services (including consulting services) and organization of courses. The professional team of “LabCare & Consulting” with its professional knowledge and many years of experience is one of the firsts in the Republic of Armenia, if not the only one in this field, which can become your partner, employee and assistant both at the stages of creating, improving ...',
            RUS: 'Оказание услуг, организация курсов и предоставление консультационных услуг. Профессиональная команда компании LabCare & Consulting со своими профессиональными знаниями и многолетним опытом - одна из первых в Армении, если не единственная в этой области, которая может стать вашим партнером, сотрудником и помощником как на этапах создания, улучшения и оснащения ...'
        },
        icon: "https://www.dropbox.com/s/qatmttp502foppi/coaching.png?raw=1",
        backgroundColor: WHITE_HEX,
        color: BLACK_HEX
    },
    law: {
        title: {
            ARM:"Իրավական խորհրդատվություն և ծառայություններ ",
            ENG:"Legal consulting and services ",
            RUS:""
        },
        desc: [
            {
                type: 'text',
                ARM: '<<ԼաբՔեր Ընդ Քընսալթինգ>> ընկերության իրավաբանական խմբի կողմից տրամադրվում է արագ և արդյունավետ իրավական խորհրդատվություն և մատուցվում են ծառայություններ հայերենով և անգլերենով, մասնավորապես՝',
                ENG: "The legal team of the “LabCare & Consulting” Company provides fast and effective legal consulting and services in English and Armenian, especially the following:",
                RUS: ''
            },
            {
                type: 'text',
                ARM: '- Պայմանագրերի և այլ իրավական փաստաթղթերի կազմում, զննում և խմբագրում, այդ թվում՝ արտասահմանյան ընկերությունների հետ գործարքներում',
                ENG: '- Legal support in temporary and permanent residency issues in Armenia',
                RUS: ''
            },
            {
                type: 'text',
                ARM: '- Փաստաբանական ծառայություններ քաղաքացիական գործերով (այդ թվում՝ հայցադիմումների կազմում և դատական ներկայացուցչություն)',
                ENG: '- Legal support for non-resident foreign companies in Armenia',
                RUS: ''
            },
            {
                type: 'text',
                ARM: '- Իրավաբանական անձանց (ՍՊԸ, ՓԲԸ, մասնաճյուղ, ներկայացուցչություն, ՀԿ, Հիմնադրամ) կամ անհատ ձեռնարկատիրոջ (Ա/Ձ) հիմնադրում/գրանցում, կանոնադրության մեջ փոփոխությունների իրականացում (իրավաբանական հասցեի և այլն), վերակազմակերպում և լուծարում',
                ENG: '- Drafting and reviewing contracts and other legal documents',
                RUS: ''
            },
            {
                type: 'text',
                ARM: '- Ապրանքային նշանների (լոգոների), գյուտերի գրանցում, ցանկացած այլ իրավական գործողության իրականացում՝ կապված մտավոր սեփականության հետ',
                ENG: '- Legal support in civil law and litigations in Armenian Courts',
                RUS: ''
            },
            {
                type: 'text',
                ARM: '- Լիցենզիաների ձեռքբերման գործընթացի իրավական աջակցություն',
                ENG: '- Legal support in civil law and litigations in Armenian Courts',
                RUS: ''
            },
            {
                type: 'text',
                ARM: '-Իրավաբանական անձանց սպասարկում՝ աշխատանքային պայմանագրերի, հրամանների և այլ ներքին իրավական ակտերի կազմում',
                ENG: '-Translation of legal documents from English to Armenian and vice versa',
                RUS: ''
            },
            {
                type: 'text',
                ARM: '-Իրավական փաստաթղթերի թարգմանություն հայերենից անգլերեն և հակառակը։',
                ENG: '-Legal support in obtaining different types of licenses',
                RUS: ''
            },
            {
                type: 'text',
                ARM: '-Legal support in founding legal persons (LLC, CJSC, NGO, Foundation, etc.) and Sole Proprietors (S/P) and related issues with their businesses.',
                ENG: '-Registration and other related services regarding trademarks and patents',
                RUS: ''
            },
        ],
        minDesc: {
            ARM: '<<ԼաբՔեր Ընդ Քընսալթինգ>> ընկերության իրավաբանական խմբի կողմից տրամադրվում է արագ և արդյունավետ իրավական խորհրդատվություն և մատուցվում են ծառայություններ հայերենով և անգլերենով, մասնավորապես՝ Պայմանագրերի և այլ իրավական փաստաթղթերի կազմում, զննում և խմբագրում, այդ թվում՝ արտասահմանյան ընկերությունների հետ գործարքներում ...',
            ENG: 'The legal team of the “LabCare & Consulting” Company provides fast and effective legal consulting and services in English and Armenian, especially the following: Legal support in temporary and permanent residency issues in Armenia Legal support for non-resident foreign companies in Armenia Drafting and reviewing contracts and other legal documents ...',
            RUS: ''
        },
        icon: "https://www.dropbox.com/s/u2yqxlwi14fkz2o/balance.png?raw=1",
        backgroundColor: GREEEN_HEX,
        color: WHITE_HEX
    },
}

export const pictures = {
    lab: 'https://www.dropbox.com/s/khrlq8h91sby0y9/lab%20serv2.jpg?raw=1',
    mining: 'https://www.dropbox.com/s/pce4w1ww51b8g1b/mine%20serv1.jpg?raw=1',
    yeast: 'https://www.dropbox.com/s/gj1pc687zaxswjr/yeast1.jpg?raw=1',
    trainings: 'https://www.dropbox.com/s/i91das6pcp6fiqx/consultansy.jpeg?raw=1',
    law: 'https://www.dropbox.com/s/3kg94qu31hcp84l/LegalService.jpg?raw=1',
}