export const title = {
    ARM: 'մեր կոնտակտներ',
    ENG: 'our contacts',
    RUS: 'наши контакты',
}

export const data = [
    {
        title: {
            ARM: '',
            ENG: '',
            RUS: ''
        },
        secondary: 'labcarecons@gmail.com',
        src: 'https://www.dropbox.com/s/zqvt8fy0c5ikjx3/mail.png?raw=1',
    },
    {
        title: {
            ARM: '',
            ENG: '',
            RUS: ''
        },
        secondary: '+37477473021',
        src: 'https://www.dropbox.com/s/ykkx2pag99led19/phone-call.png?raw=1',
    },
    {
        title: {
            ARM: '',
            ENG: '',
            RUS: ''
        },
        secondary: {
            ENG: '74 A Nairi Zaryan, 0051, Yerevan Armenia',
            ARM: 'Նաիրի Զարյան 74 Ա, 0051, Երևան, Հայաստանի Հանրապետություն',
            RUS: 'Наири Зарян 74 А, 0051, Ереван, Армения'
        },
        src: 'https://www.dropbox.com/s/lef0iyhcv5wfp8o/placeholder.png?raw=1',
    },
];