export const mainTitle = {
    ARM: "Բարի գալուստ labcare",
    ENG: "Welcome to labcare",
    RUS: "Добро пожаловать в labcare"
};

export const ourMission = {
    title: {
        ARM: "մեր առաքելությունը",
        ENG: "our mission",
        RUS: "Наша миссия"
    },
    desc: [
        {
            type: 'text',
            ARM: 'ԼաբՔեր Ընդ Քընսալթինգ ընկերության հիմնադրման գլխավոր նպատակն է իր գործունեությամբ  օժանդակել Հայաստանի Հանրապետության տնտեսության մաս կազմող արդյունաբերական և վերլուծական լաբորատորիաների ենթակառուցվածքների զարգացմանը: Նշված նպատակին հասնելու ճանապարհին  Ընկերության գործունեությունը հիմնվում է  հետևյալ արժեքների վրա՝',
            ENG: "The main goal of the “LabCare & Consulting” company is to support the development of the infrastructure of production and analytical laboratories, which are part of the economy of the Republic of Armenia. On the way to achieving this goal, the Company's activities are based on the following core values:",
            RUS: 'Основная цель создания компании LabCare & Consulting - поддержка развития инфраструктуры производственных и аналитических лабораторий, которые являются частью экономики Республики Армения. На пути к достижению этой цели деятельность Компании основывается на следующих ценностях:'
        },
        {
            type: 'listItem',
            ARM: 'Գործընկերներին և հաճախորդներին ցուցաբերել անհատական, պրոֆեսիոնալ, հարգալից մոտեցում, բավարարել և գերազանցել նրանց սպասելիքները՝ ապահովելով ցանակալի արդյունքին հասնելու երաշխիք,',
            ENG: 'Provide an individual, professional and respectful approach to partners and clients, meet and, if possible, exceed their expectations, ensuring the guaranteed achievement of the desired result.',
            RUS: 'Обеспечить индивидуальный, профессиональный и уважительный подход к партнерам и клиентам, оправдать и по возможности превзойти их ожидания, обеспечивая гарантированное достижение желаемого результата;'
        },
        {
            type: 'listItem',
            ARM: 'Ձևավորել փոխվստահության, փոխըմբռման, ազնվության վրա հիմնված փոխշահավետ գործընկերային հարաբերություններ,',
            ENG: 'Develop mutually beneficial partnerships based on trust, understanding and honesty.',
            RUS: 'Развивать взаимовыгодные партнерские отношения, основанные на взаимном доверии, понимании и честности'
        },
        {
            type: 'listItem',
            ARM: 'Ձևավորել գիտելիքների, փորձի փոխանակման վրա հիմնված արդյունավետ հաղորդակցություն, մշտապես կատարելագործվել և ձգտել լինել ոլորտում լավագույնը:',
            ENG: ' Promote quality and effectiveness through communication, sharing of new knowledge and theories, best-in-class technical assistance, and forever strive for excellence in all we do.',
            RUS: 'Развивать эффективную коммуникацию на основе знаний, обмена опытом, постоянно совершенствоваться и стремиться быть лучшими в своей области.'
        },
        {
            type: 'text',
            ARM: 'Մենք գիտակցում ենք, որ  մեզ շրջապատող միջավայրը, տեխնոլոգիան և համաշխարհային արդյունաբերությունը մշտապես գտնվում են դինամիկ զարգացման և փոփոխման մեջ, ինչն էլ իր հերթին թելադրում է մեզ հետևել փոփոխություններին, ընդունել դրանք, գործել դինամիկ զարգացմանը համընթաց՝ ապահովելով մեր հաճախորդներին և գործընկերներին այն բոլոր գործիքակազմով, որն իրենք պահանջում են մեզանից ցանկացած ժամանակ և ցանկացած վայրից:',
            ENG: 'We recognize that our environment, technologies and the global industry will continue to evolve and how we adapt to change is crucial to the long-term resilience and viability of our business. With a long history of innovation and many industrial fields, we pledge to stay dynamic, work hard and equip our clients with the tools they need, whenever and wherever. ',
            RUS: 'Хорошо понимая, что отрасль, технологии и промышленность постоянно развиваются и меняются, наша команда пристально следит за изменениями, принимает их и действует параллельно, развиваясь динамично, предоставляя своим клиентам и партнерам все необходимые инструменты в любое время и в любом месте.'
        },
    ],
    img: 'https://www.dropbox.com/s/7b37b8l6uu2ttc7/our%20mission6.jpg?raw=1'
};

export const ourStrategy = {
    title: {
        ARM: "մեր ռազմավարությունը",
        ENG: "our strategy",
        RUS: "наша стратегия"
    },
    desc: [
        {
            type: 'text',
            ARM: 'Ցանկացած հաջող բիզնեսի հիմքում կանգնած է պրոֆեսիոնալ աշխատանքային թիմը: ԼաբՔեր Ընդ Քընսալթինգ ընկերությունը մեծ թիմ-ընտանիք է՝ իր հաջողություններով ու խնդիրներով: Մեր ընտանիքի ամենամեծ ձեռքբերումն  այն ներդաշնակ մթնոլորտն է, որը մենք այսօր ունենք: Այդ մթնոլորտի արդյունքում ամեն ինչ կառուցվում է փոխըմբռման և ներդաշնակության հենքի վրա: Մեր որդեգրած ռազմավարությունն  է',
            ENG: 'At the heart of any successful business is a professional team. “LabCare & Consulting” is a large family that has its own successes and problems. The greatest achievement of our company is the atmosphere of harmony prevailing today. Thanks to such an atmosphere, everything is built on mutual understanding and harmony. Our strategy is as follows:',
            RUS: 'В основе любого успешного бизнеса - профессиональная команда. LabCare & Consulting - это большая команда, у которой есть свои и успехи и проблемы. Самым большим достижением нашей компании является царящая сегодня атмосфера гармонии. Благодаря такой атмосфере все строится на взаимопонимании и гармонии. Наша стратегия:'
        },
        {
            type: 'text',
            ARM: '-ՈՒնենալ հուսալի գործընկերներ ՀՀ շուկայում՝ օժանդակելով  տնտեսության համապատասխան ոլորտներում առաջացող խնդիրների լուծմանը,և շուկայի  զարգացմանը:',
            ENG: '-To have reliable partners in the RA and abroad who support us in solving problems arising in the relevant sectors of the economy, which effects on the development of the market economy of our country.',
            RUS: '-иметь надежных партнеров в РА и за ее пределами, которые поддерживают нас в решении проблем, возникающих в соответствующих отраслях экономики, что влияет на развитие рыночной экономики нашей страны.'
        },
        {
            type: 'text',
            ARM: '-Ստեղծել ծառայությունների պատշաճ մատուցման ամբողջական միասնական համակարգ:',
            ENG: '-To create a unified system for the provision of professional services.',
            RUS: '-создание единой системы предоставления профессиональных услуг.'
        },
        {
            type: 'text',
            ARM: '-Հիմքում ունենալով միջազգային փորձը ուսուցանել, պատրաստել և վերապատրաստել  ՀՀ, նորարար, տարբերվող մոտեցումներ ունեցող ոլորտի մասնագետներ:',
            ENG: '-To train, retrain and re-profile the RA industry specialists, based on the best international experience and using various innovative approaches.',
            RUS: '-обучать, переобучать и перепрофилировать отраслевых специалистов РА,основываясь на лучший международный опыт и используя различные инновационные подходы.'
        },
    ],
    img: 'https://www.dropbox.com/s/7iw79bi3piabf6m/our%20straegy1.jpeg?raw=1'
};